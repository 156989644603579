module.exports = [{
      plugin: require('../node_modules/@mkitio/gatsby-theme-password-protect/gatsby-browser.js'),
      options: {"plugins":[],"pagePaths":["/data-lake-v4","/pre-employment-screening-system","/data-lake-v4/","/pre-employment-screening-system/"],"password":"elam2021"},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Eric Lam Portfolio","short_name":"Eric Lam","start_url":"/","background_color":"#000000","theme_color":"#212121","display":"standalone","icon":"./src/images/logo.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"1cfc7ba16aa1face63d0a1792f00194c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
